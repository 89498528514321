
// just an idea right now, not in use

import { reactive, type Ref, type Reactive } from "vue";
import zxing from "zxing-browser";

export default class BrowserQRCodeReader {
    videoRef: Ref<HTMLVideoElement>;
    onScan: Function;

    reader: any = new zxing.BrowserQRCodeReader();
    controls?: any;

    running: boolean = false;

    constructor(videoRef: Ref<HTMLElement>, onScan: (res: any, err: any) => void) {
        this.videoRef = videoRef;
        this.onScan = onScan;
    }

    async start(): Promise<void> {
        this.running = true;
        this.controls = await this.reader.decodeFromVideoDevice(null, this.videoRef.value, this.onScan);
    }

    async stop(): Promise<void> {
        if (this.controls) {
            await this.controls.stop();
            this.running = false;
        }
    }
}

export function useBrowserQRCodeReader(...args: ConstructorParameters<typeof BrowserQRCodeReader>): Reactive<BrowserQRCodeReader> {
    return reactive<BrowserQRCodeReader>(new BrowserQRCodeReader(...args));
}
